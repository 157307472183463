import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    login(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .get('https://posapi.owlstime.com/api/AppBranchLogin/BranchLogin', {
            params: {
              Login: userData.userName,
              Password: userData.password,
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
