<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <b-img
            fluid
            :src="imgUrl"
          />
        </b-link>

        <b-card-title class="mb-1">
          Welcome to POS ! 👋
        </b-card-title>
        <b-card-text class="mb-2">
          Please sign-in to your account
        </b-card-text>

        <!-- form -->
        <validation-observer
          ref="loginForm"
          #default="{invalid}"
        >
          <b-form
            class="auth-login-form mt-2"
            @submit.prevent="login"
          >
            <b-form-group
              label="Username"
              label-for="username"
            >
              <validation-provider
                #default="{ errors }"
                name="Username"
                vid="username"
                rules="required"
              >
                <b-form-input
                  id="username"
                  v-model="username"
                  :state="errors.length > 0 ? false:null"
                  name="username"
                  autofocus
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- password -->
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="login-password">Password</label>
              </div>
              <validation-provider
                #default="{ errors }"
                name="Password"
                vid="password"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="login-password"
                    v-model="password"
                    :state="errors.length > 0 ? false:null"
                    class="form-control-merge"
                    :type="passwordFieldType"
                    name="login-password"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- checkbox -->
            <b-form-group>
              <b-form-checkbox
                id="remember-me"
                v-model="status"
                name="checkbox-1"
              >
                Remember Me
              </b-form-checkbox>
            </b-form-group>

            <!-- submit button -->
            <b-button
              variant="primary"
              type="submit"
              block
              :disabled="invalid"
            >
              Sign in
            </b-button>
          </b-form>
        </validation-observer>
      </b-card>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BFormCheckbox, BCard, BCardText, BCardTitle, BImg, BForm, BButton, BAlert, VBTooltip,
} from 'bootstrap-vue'
import { required } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useJwt from '@/auth/jwt/useJwt'
import { onUnmounted } from '@vue/composition-api'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import jwt from 'jsonwebtoken'
import authenStoreModule from './authenStoreModule'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCard,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      // password: 'admin',
      // username: 'admin@demo.com',
      username: '',
      password: '',
      sideImg: require('@/assets/images/logo/logo.png'),
      // validation rules
      required,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      // if (store.state.appConfig.layout.skin === 'dark') {
      //   // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      //   this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
      //   return this.sideImg
      // }
      return this.sideImg
    },
  },
  setup() {
    // Register module
    if (!store.hasModule('authen')) store.registerModule('authen', authenStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('authen')) store.unregisterModule('authen')
    })
  },
  methods: {
    login() {
      this.$refs.loginForm.validate().then(success => {
        if (success) {
          const userModel = {
            id: 0,
            deviceId: '',
            userName: this.username,
            password: this.password,
            newPassword: '',
            secret: '',
          }

          store.dispatch('authen/login', userModel)
            .then(response => {
              if (response.status === 200) {
                if (response.data.length > 0) {
                  const resUser = response.data[0]
                  const accessToken = jwt.sign({ id: resUser.id }, 'dd5f3089-40c3-403d-af14-d0c228b05cb4', { expiresIn: '10m' })

                  const userData = {
                    userId: resUser.id,
                    userName: resUser.login,
                    fullName: resUser.login,
                    branchId: resUser.branch_id,
                    token: accessToken,
                    // theme ACL
                    role: 'admin',
                    ability: [
                      {
                        action: 'manage',
                        subject: 'all',
                      },
                    ],
                  }

                  // console.log(userData)

                  useJwt.setToken(userData.token)
                  // useJwt.setRefreshToken(response.data.refreshToken)

                  localStorage.setItem('userData', JSON.stringify(userData))

                  this.$ability.update(userData.ability)

                  this.$router.replace(getHomeRouteForLoggedInUser(userData.role))
                    .then(() => {
                      this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                          title: `Welcome ${userData.fullName || userData.userName}`,
                          icon: 'CoffeeIcon',
                          variant: 'success',
                          text: `You have successfully logged in as ${userData.role}. Now you can start to explore!`,
                        },
                      })
                    })
                }
              }
            })
            .catch(error => {
              // this.showToast('danger', 'XCircleIcon', error.response.data)
              this.$refs.loginForm.setErrors(error.response.data.error)
            })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
